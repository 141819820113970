import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import Form, { useFormValues } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Select, { Option } from '@asteria/component-form/select';
import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import LayoutContext from '../../layout/context';

import './styles.scss';

function SupportFormContent({ loading }) {
	const value = useFormValues({ name: 'type' });

	return (
		<>
			<Select
				name="type"
				label={TranslationService.get(['support.form.subject.label'])}
				placeholder={TranslationService.get([
					'support.form.subject.placeholder',
				])}
				icon="chevron-down"
				iconActive="chevron-up"
				required
				scroll
			>
				<Option value="account">
					{TranslationService.get([
						'support.form.subject.option.label.account',
					])}
				</Option>
				<Option value="connection">
					{TranslationService.get([
						'support.form.subject.option.label.connection',
					])}
				</Option>
				<Option value="invoice_missing_partner">
					{TranslationService.get([
						'support.form.subject.option.label.invoice_missing_partner',
					])}
				</Option>
				<Option value="invoice_missing">
					{TranslationService.get([
						'support.form.subject.option.label.invoice_missing',
					])}
				</Option>
				<Option value="invoicing_error">
					{TranslationService.get([
						'support.form.subject.option.label.invoicing_error',
					])}
				</Option>
				<Option value="custom">
					{TranslationService.get([
						'support.form.subject.option.label.custom',
					])}
				</Option>
			</Select>
			{value === 'custom' ? (
				<Input
					name="subject-custom"
					label={TranslationService.get([
						'support.form.subject.custom.label',
					])}
					placeholder={TranslationService.get([
						'support.form.subject.custom.placeholder',
					])}
					required
				/>
			) : null}
			<Input
				name="email"
				type="email"
				label={TranslationService.get(['support.form.email.label'])}
				placeholder={TranslationService.get([
					'support.form.email.placeholder',
				])}
				required
			/>
			<Input
				name="message"
				type="textarea"
				label={TranslationService.get(['support.form.message.label'])}
				placeholder={TranslationService.get([
					'support.form.message.placeholder',
				])}
				required
			/>
			<div className="flex items-center">
				{value ? (
					<Translation
						className="grow"
						translationKey={`page.support.form.type.${
							value ?? ''
						}.info`}
						showWhenEmpty={false}
					/>
				) : null}
				<Button
					type="submit"
					label={TranslationService.get([
						'button.submit',
						'action.submit',
						'support.form.submit',
						'page.support.form.submit',
					])}
					variant="primary"
					loading={loading}
					disabled={loading}
				/>
			</div>
		</>
	);
}

SupportFormContent.displayName = 'SupportFormContent';
SupportFormContent.propTypes = { loading: PropTypes.bool };

function SupportForm(props) {
	const { onAction, country } = props;

	const [loading, setLoading] = React.useState(false);

	const { onSubmit } = React.useContext(LayoutContext);

	const lending = useSelector(
		(store) => AppStore.selectors.company(store)?.service?.data?.lending,
	);

	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank;
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.();
		}

		return bank?.value?.toLowerCase?.();
	});

	const placeholder = useConfig('pages.support.form.placeholder', {
		deep: true,
	});

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	const defaultValues = React.useMemo(
		() => ({ country: country }),
		[country],
	);

	const handleSubmit = React.useCallback(
		async ({ type, 'subject-custom': custom, ...form }) => {
			setLoading(true);

			try {
				const response = await onSubmit?.('support', {
					...form,
					type: `${country}:${lending}:${bank}:${type}`,
					subject:
						type === 'custom'
							? custom
							: TranslationService.get([
									`support.form.subject.option.label.${type}`,
							  ]),
				});

				return onAction?.('form:success', response);
			} catch (err) {
				// handle error
			} finally {
				setLoading(false);
			}
		},
		[onAction, onSubmit, bank, country, lending],
	);

	return (
		<div
			className={cn('asteria-page__support-form', {
				'asteria--state-split': placeholder?.length,
			})}
		>
			<div className="asteria-page__support-form__content">
				<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
					<SupportFormContent loading={loading} />
				</Form>
			</div>
			{placeholder?.length ? (
				<div className="asteria-page__support-form__placeholder">
					<Contenter content={placeholder} actions={actions} />
				</div>
			) : null}
		</div>
	);
}

SupportForm.displayName = 'SupportForm';
SupportForm.propTypes = { onAction: PropTypes.func, country: PropTypes.string };

export default React.memo(SupportForm);
