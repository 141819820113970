import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { get, isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import { Input } from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function filterClients(type) {
	return function (client) {
		if (type === 'delivery') {
			const form = client?.service?.client;
			const method =
				form?.delivery?.method ??
				client?.info?.invoiceDeliveryWay ??
				null;

			return method === null;
		}

		if (type === 'errors') {
			return !!client?.errors?.length;
		}

		return true;
	};
}

const ClientListRow = React.memo(function ClientListRow(props) {
	const { id, onSelect } = props;

	const object = useSelector(
		(store) => AppStore.selectors.client(store, id),
		isEqual,
	);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const ID = object?._id ?? object?.id;

	const form = object?.service?.client;
	const method =
		form?.delivery?.method ?? object?.info?.invoiceDeliveryWay ?? null;

	const error = (object?.errors ?? [])?.[0];

	return (
		<TableRow
			key={ID}
			className={cn({
				'asteria--variant-error': !!error,
			})}
		>
			<TableCell>
				<Translation
					translationKey="client.list.table.row.value"
					defaultText="{{{ client.name }}}"
					translationOptions={{
						postfix: { type: 'name' },
						data: { client: object },
					}}
					Component={Text}
				/>
			</TableCell>
			<TableCell>
				<Translation
					translationKey="client.list.table.row.value"
					defaultText="{{{ client.meta.clientNumber }}}"
					translationOptions={{
						postfix: { type: 'number' },
						data: { client: object },
					}}
					Component={Text}
				/>
			</TableCell>

			{companyService === 'INVOICE' ? (
				<TableCell>
					{!method ? (
						<Badge
							size="sm"
							icon="warning"
							tooltip={TranslationService.get([
								'client.list.badge.delivery.tooltip',
								'modal.client.list.badge.delivery.tooltip',
							])}
						/>
					) : (
						<Text>
							{TranslationService.get(
								[
									`client.overview.details.service.delivery.method.value.${method}`,
									`client.overview.details.${object?.type}.service.delivery.method.value.${method}`,
								],
								undefined,
								{ client: object },
							)}
						</Text>
					)}
				</TableCell>
			) : null}

			<TableCell className="asteria--type-error">
				<Translation
					translationKey={[
						'client.error.details',
						'client.list.table.row.value',
					]}
					defaultText="{{{ error.message }}}"
					translationOptions={{
						postfix: {
							type: 'error-message',
							code: error?.code,
							multi: object?.errors?.length > 1,
						},
						data: { client: object, error },
					}}
					Component={Text}
				/>
				{error ? (
					<Badge
						size="sm"
						icon="warning"
						tooltip={TranslationService.getV2(
							[
								'client.error.details',
								'client.list.table.row.value',
								'client.list.table.row.value.tooltip',
							],
							{
								postfix: {
									type: 'error-tooltip',
									code: error?.code,
									multi: object?.errors?.length > 1,
								},
								data: {
									client: object,
									error: error,
								},
								default: '{{{ error.message }}}',
							},
						)}
					/>
				) : null}
			</TableCell>

			<TableCell>
				<Button icon="chevron-right" size="sm" onClick={onSelect(ID)} />
			</TableCell>
		</TableRow>
	);
});

ClientListRow.propTypes = { id: PropTypes.string, onSelect: PropTypes.func };

const ClientList = (props) => {
	const { onClose, type: $type = null } = props;

	const dispatch = useDispatch();

	const [{ type, sort, search }, onUpdate] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'SET_TYPE': {
					if (state.type === action?.payload) {
						return state;
					}

					return {
						...state,
						type: action?.payload,
						// sort: { name: 'meta.clientNumber', direction: 'ASC' },
						search: '',
					};
				}

				case 'SEARCH':
					return { ...state, search: action?.payload };

				case 'SET_SORT': {
					const sort = { ...state.sort };

					if (sort.name === action?.payload) {
						sort.direction =
							sort.direction === 'ASC' ? 'DESC' : 'ASC';
					} else {
						sort.name = action?.payload;
						sort.direction = 'ASC';
					}

					return { ...state, sort: sort };
				}

				default:
					return state;
			}
		},
		{
			sort: { name: 'meta.clientNumber', direction: 'ASC' },
			type: null,
			search: '',
		},
	);

	React.useEffect(() => {
		onUpdate({ type: 'SET_TYPE', payload: $type });
	}, [$type]);

	const clients = useSelector(
		(store) =>
			AppStore.selectors
				.clients(store)
				.filter(filterClients(type))
				.map((object) => ({ ...object, error: object?.errors?.[0] }))
				.sort((a, b) => {
					const source = get(a, sort.name);
					const target = get(b, sort.name);

					if (
						!isNaN(Number.parseFloat(source)) &&
						!isNaN(Number.parseFloat(target))
					) {
						if (sort.direction === 'ASC') {
							return (
								Number.parseFloat(source) -
								Number.parseFloat(target)
							);
						}

						return (
							Number.parseFloat(target) -
							Number.parseFloat(source)
						);
					}

					if (sort.direction === 'ASC') {
						return source?.localeCompare?.(target);
					}

					return target?.localeCompare?.(source);
				}),
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		if (!clients.length && type === 'delivery') {
			onUpdate({ type: 'SET_TYPE', payload: null });
		}
	}, [clients.length, type]);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const { delivery: clientMissingDeliveryCount, errors: clientErrorCount } =
		useSelector((store) => {
			const clients = AppStore.selectors.clients(store);

			return clients.reduce(
				(acc, object) => {
					const form = object?.service?.client;
					const method =
						form?.delivery?.method ??
						object?.info?.invoiceDeliveryWay ??
						null;
					const errors = object?.errors;

					acc.delivery += method === null;
					acc.errors += !!errors?.length;

					return acc;
				},
				{ delivery: 0, errors: 0 },
			);
		});

	const onSelect = React.useCallback(
		(id) => {
			return () => {
				dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.ClientOverview,
						data: { _id: id },
					}),
				);
			};
		},
		[dispatch],
	);

	const onSort = React.useCallback(
		(name) => () => onUpdate({ type: 'SET_SORT', payload: name }),
		[],
	);

	const onSetType = React.useCallback(
		(type) => () => onUpdate({ type: 'SET_TYPE', payload: type }),
		[],
	);

	const onSearch = React.useCallback(
		({ value }) => onUpdate({ type: 'SEARCH', payload: value }),
		[],
	);

	const selectedClients = React.useMemo(() => {
		if (!search) {
			return clients;
		}

		return clients.filter((object) => {
			const name = get(object, 'name');
			const clientNumber = get(object, 'meta.clientNumber');
			const deliveryMethod = get(
				object,
				'service.client.delivery.method',
			);

			const error = TranslationService.getV2(
				['client.error.details', 'client.list.table.row.value'],
				{
					postfix: {
						type: 'error-message',
						code: object?.error?.code,
						multi: object?.errors?.length > 1,
					},
					data: { client: object, error: object?.errors?.[0] },
					default: '{{{ error.message }}}',
				},
			);

			return [name, clientNumber, deliveryMethod, error].some((value) =>
				value?.toLowerCase?.()?.includes?.(search?.toLowerCase?.()),
			);
		});
	}, [clients, search]);

	const shouldShowDeliveryChip =
		!!clientMissingDeliveryCount && companyService === 'INVOICE';
	const shouldShowErrorChip = !!clientErrorCount;
	const shouldShowSelectors = shouldShowDeliveryChip || shouldShowErrorChip;

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get([
					'client.list.title',
					'modal.client.list.title',
				])}
			</Header>
			<Content scroll>
				<Input
					uncontrolled
					value={search}
					onChange={onSearch}
					icon="magnifier"
					placeholder={TranslationService.get(
						[
							'client.list.search.placeholder',
							'modal.client.list.search.placeholder',
						],
						undefined,
						{
							clients: clients,
							delivery: clientMissingDeliveryCount,
						},
					)}
				/>

				{shouldShowSelectors ? (
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="left"
						className="asteria-component__client-modal__filters"
					>
						<Chip
							size="sm"
							label={TranslationService.get(
								[
									'client.list.filters.all',
									'modal.client.list.filters.all',
								],
								undefined,
								{
									clients: clients,
									delivery: clientMissingDeliveryCount,
									errors: clientErrorCount,
								},
							)}
							onClick={onSetType(null)}
							active={type === null}
						/>
						{shouldShowDeliveryChip ? (
							<Chip
								size="sm"
								label={TranslationService.get(
									[
										'client.list.filters.delivery-method',
										'modal.client.list.filters.delivery-method',
									],
									undefined,
									{
										clients: clients,
										delivery: clientMissingDeliveryCount,
										errors: clientErrorCount,
									},
								)}
								onClick={onSetType('delivery')}
								active={type === 'delivery'}
							/>
						) : null}
						{shouldShowErrorChip ? (
							<Chip
								size="sm"
								label={TranslationService.get(
									[
										'client.list.filters.errors',
										'modal.client.list.filters.errors',
									],
									undefined,
									{
										clients: clients,
										delivery: clientMissingDeliveryCount,
										errors: clientErrorCount,
									},
								)}
								onClick={onSetType('errors')}
								active={type === 'errors'}
							/>
						) : null}
					</Group>
				) : null}

				<Table
					className={cn('asteria--type-clients', {
						'asteria--state-delivery': type === 'delivery',
						'asteria--state-errors': type === 'errors',
						'asteria--variant-service-invoice':
							companyService === 'INVOICE',
					})}
				>
					<TableHeader>
						<TableCell>
							<Button
								label={TranslationService.get([
									'client.list.table.header.label',
									'modal.client.list.table.header.label',
									'client.list.table.header.name.label',
									'modal.client.list.table.header.name.label',
								])}
								icon="chevron-down"
								iconActive={
									sort?.direction === 'ASC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								active={sort?.name === 'name'}
								onClick={onSort('name')}
							/>
						</TableCell>
						<TableCell>
							<Button
								label={TranslationService.get([
									'client.list.table.header.label',
									'modal.client.list.table.header.label',
									'client.list.table.header.meta.clientNumber.label',
									'modal.client.list.table.header.meta.clientNumber.label',
								])}
								icon="chevron-down"
								iconActive={
									sort?.direction === 'ASC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								active={sort?.name === 'meta.clientNumber'}
								onClick={onSort('meta.clientNumber')}
							/>
						</TableCell>

						{companyService === 'INVOICE' ? (
							<TableCell>
								<Button
									label={TranslationService.get([
										'client.list.table.header.label',
										'modal.client.list.table.header.label',
										'client.list.table.header.service.delivery.method.label',
										'modal.client.list.table.header.service.delivery.method.label',
									])}
									icon="chevron-down"
									iconActive={
										sort?.direction === 'ASC'
											? 'chevron-down'
											: 'chevron-up'
									}
									iconPosition="last"
									active={
										sort?.name ===
										'service.client.delivery.method'
									}
									onClick={onSort(
										'service.client.delivery.method',
									)}
								/>
							</TableCell>
						) : null}

						<TableCell>
							<Button
								label={TranslationService.get([
									'client.list.table.header.label',
									'modal.client.list.table.header.label',
									'client.list.table.header.error.message.label',
									'modal.client.list.table.header.error.message.label',
								])}
								icon="chevron-down"
								iconActive={
									sort?.direction === 'ASC'
										? 'chevron-down'
										: 'chevron-up'
								}
								iconPosition="last"
								active={sort?.name === 'error.message'}
								onClick={onSort('error.message')}
							/>
						</TableCell>

						<TableCell />
					</TableHeader>
					{selectedClients.length ? (
						selectedClients.map((object) => {
							const id = object?._id ?? object?.id;

							return (
								<ClientListRow
									key={id}
									id={id}
									onSelect={onSelect}
								/>
							);
						})
					) : (
						<TableRow>
							<TableCell className="asteria--type-empty">
								<Text>
									{TranslationService.get([
										'client.list.table.empty.label',
										'modal.client.list.table.empty.label',
									])}
								</Text>
							</TableCell>
						</TableRow>
					)}
				</Table>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'client.list.action.close',
							'modal.client.list.action.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

ClientList.displayName = 'ClientList';
ClientList.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	type: PropTypes.string,
};

const ClientListModal = (props) => {
	const { className, open, onClose } = props;

	return (
		<Modal
			open={open}
			className={cn(
				'asteria-component__client-modal',
				'asteria--type-list',
				className,
			)}
			onClose={onClose}
		>
			<ClientList {...props} />
		</Modal>
	);
};

ClientListModal.displayName = 'ClientListModal';

ClientListModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	type: PropTypes.string,
};

export default ClientListModal;
