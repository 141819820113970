import { useEffect, useState } from 'react';

import { AuthService } from '@asteria/backend-utils-services';

export function useUsername() {
	const [name, setName] = useState(null);

	useEffect(() => {
		async function fetchName(token = localStorage.wingsToken) {
			if (!token) {
				return;
			}

			await AuthService.auth
				.me({ fields: `firstName lastName fullName` }, { token: token })
				.then((object) => {
					if (object?.fullName) {
						return object?.fullName;
					}

					return [object?.firstName, object?.lastName]
						.filter(Boolean)
						.join(' ');
				})
				.then((name) => {
					if (name) {
						setName(name);
					}
				})
				.catch(() => null);
		}

		fetchName();

		function subscribe({ key, value }) {
			if (key === 'wingsToken') {
				fetchName(value);
			}
		}

		document.addEventListener('localStorage.set', subscribe);

		return () => {
			document.removeEventListener('localStorage.set', subscribe);
		};
	}, []);

	return name;
}
