import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Badge, { BadgeWrapper } from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import List, { ListGroup, ListItem } from '@asteria/component-list';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useClient, useClientErrors } from '../hooks';

/**
 * @param { unknown } data
 * @param { { name: string, value?: unknown, label: string } } options
 */
function useDefaultLabel(data, options) {
	return React.useMemo(() => options?.label, [options?.label]);
}

/**
 * @param { unknown } data
 * @param { { name: string, value?: unknown, label: string } } options
 */
function useDefaultVisibility() {
	return React.useMemo(() => true, []);
}

/**
 * @param { unknown } data
 * @param { { name: string, value?: unknown, label: string } } options
 */
function useDefaultError(data, options) {
	const error = (data?.errors ?? []).find(
		({ path }) => path === options?.name,
	);

	return React.useMemo(() => error, [error]);
}

const ClientDetailsInfoItem = (props) => {
	const {
		className,
		name,
		value,
		data,
		useLabel = useDefaultLabel,
		useVisibility = useDefaultVisibility,
		useError = useDefaultError,
	} = props;

	const label = useLabel(data, { name, value, label: props?.label });
	const visible = useVisibility(data, { name, value, label: props?.label });
	const error = useError(data, { name, value, label: props?.label });

	if (!visible) {
		return null;
	}

	return (
		<ListItem
			className={cn(
				'asteria-component__client-overview__details__info__item',
				{ 'asteria--state-error': !!error },
				className,
			)}
		>
			<div className="asteria-component__client-overview__details__info__item__label">
				<Text size="sm">{label}</Text>
			</div>
			<div
				className={cn(
					'flex gap-2 justify-end items-center',
					'asteria-component__client-overview__details__info__item__value',
				)}
			>
				{typeof value === 'string' ? (
					<Text size="sm">{value}</Text>
				) : (
					value
				)}
				{error ? (
					<Badge
						icon="warning"
						size="sm"
						tooltip={TranslationService.getV2(
							['client.overview.row.error.tooltip'],
							{
								postfix: { code: error?.code },
								data: { client: data, error },
								default: error?.message,
							},
						)}
					/>
				) : null}
			</div>
		</ListItem>
	);
};

ClientDetailsInfoItem.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,

	label: PropTypes.string,
	value: PropTypes.any,
	data: PropTypes.object,

	useLabel: PropTypes.func,
	useVisibility: PropTypes.func,
	useError: PropTypes.func,
};

const FieldList = React.memo(function FieldList({ values, client }) {
	return (
		<List size="lg">
			{(values ?? []).map(
				({
					label,
					value,
					name,
					alwaysVisible,
					format,
					useLabel,
					useVisibility,
				}) =>
					value || alwaysVisible ? (
						<ListGroup key={name}>
							<ClientDetailsInfoItem
								name={name}
								label={label}
								value={format ? format(value) : value}
								useLabel={useLabel}
								useVisibility={useVisibility}
								data={client}
							/>
						</ListGroup>
					) : null,
			)}
		</List>
	);
});

FieldList.propTypes = {
	values: PropTypes.arrayOf(PropTypes.object),
	client: PropTypes.object,
};

const FieldTable = React.memo(function FieldTable(props) {
	const { table, values: defaultValues, client } = props;

	const keys = ['general'].concat(Object.keys(table));

	const [current, setCurrent] = React.useState(keys[0]);

	const selected = current !== 'general' ? table?.[current] : defaultValues;

	const errors = useClientErrors({
		errors: client?.errors,
		table: { ...table, general: defaultValues },
	});

	return (
		<div className="flex flex-col gap-2">
			{keys.length > 1 ? (
				<div
					className={cn(
						'flex gap-2 pt-2',
						'asteria-component__client-overview-details-navigation',
					)}
				>
					{keys.map((key) => (
						<BadgeWrapper
							key={key}
							size="sm"
							badge={
								errors[key] ? (
									<Translation
										translationKey="client.overview.section.chip.badge"
										translationOptions={{
											data: errors[key],
											postfix: { type: key },
										}}
										defaultText={errors[key]}
										Component={Text}
										size="xs"
									/>
								) : null
							}
						>
							<Chip
								label={TranslationService.getV2(
									['client.overview.section.chip.label'],
									{
										postfix: { type: key },
										data: { client },
									},
								)}
								size="sm"
								onClick={() => setCurrent(key)}
								active={key === current}
							/>
						</BadgeWrapper>
					))}
				</div>
			) : null}
			<FieldList values={selected} client={client} />
		</div>
	);
});

FieldTable.propTypes = {
	table: PropTypes.object,
	values: PropTypes.arrayOf(PropTypes.object),
	client: PropTypes.object,
};

const ClientDetailsInfo = (props) => {
	const { className, client } = props;

	const dispatch = useDispatch();

	const form = client?.service?.client;
	const method = form?.delivery?.method ?? client?.info?.invoiceDeliveryWay ?? null;

	const id = client?._id ?? client?.id;

	const { values, table } = useClient(client).reduce(
		(acc, object) => {
			if (object?.fields) {
				for (const field of object?.fields ?? []) {
					const name = field?.name;
					const group = field?.group;
					const value = field?.value;

					if (value !== undefined) {
						if (!acc.table[group]) {
							acc.table[group] = [];
						}

						acc.table[group].push({ ...object, name, value });
					}
				}
			} else {
				acc.values.push(object);
			}

			return acc;
		},
		{ values: [], table: {} },
	);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const handleEdit = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: id },
			}),
		);
	}, [dispatch, id]);

	const clientServiceDelivery = client?.service?.client?.delivery;

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__info',
				className,
			)}
		>
			<FieldTable
				table={table}
				values={values.concat(
					[
						{
							name: 'service.delivery.method',
							label: TranslationService.get(
								[
									'client.overview.details.service.delivery.method',
									`client.overview.details.${client?.type}.service.delivery.method`,
								],
								undefined,
								{ client: client },
							),
							value:
								method && companyService === 'INVOICE'
									? TranslationService.get(
											[
												`client.overview.details.service.delivery.method.value.${method}`,
												`client.overview.details.${client?.type}.service.delivery.method.value.${method}`,
											],
											undefined,
											{ client: client },
									  )
									: null,
						},
						{
							name: 'service.delivery.van',
							label: TranslationService.get(
								[
									'client.overview.details.service.delivery.van',
									`client.overview.details.${client?.type}.service.delivery.van`,
								],
								undefined,
								{ client: client },
							),
							value:
								method && companyService === 'INVOICE'
									? clientServiceDelivery?.van ??
									  client?.delivery?.van
									: null,
						},
						{
							name: 'service.delivery.buyerId',
							label: TranslationService.get(
								[
									'client.overview.details.service.delivery.buyerId',
									`client.overview.details.${client?.type}.service.delivery.buyerId`,
								],
								undefined,
								{ client: client },
							),
							value:
								method && companyService === 'INVOICE'
									? clientServiceDelivery?.buyerId ??
									  client?.delivery?.buyerId
									: null,
						},
					].concat(
						companyService === 'INVOICE'
							? (clientServiceDelivery?.reference ?? []).map(
									({ value }, index) => ({
										name: `service.delivery.reference.${index}.value`,
										label: TranslationService.get(
											[
												'client.overview.details.service.delivery.reference',
												`client.overview.details.${client?.type}.service.delivery.reference`,
											],
											undefined,
											{
												client: client,
												index: index + 1,
											},
										),
										value: TranslationService.get(
											[
												`client.overview.details.service.delivery.reference.option.${value}`,
												`client.overview.details.${client?.type}.service.delivery.reference.option.${value}`,
											],
											undefined,
											{
												client: client,
												index: index + 1,
											},
										),
									}),
							  )
							: [],
					),
				)}
				client={client}
			/>
			{!method && companyService === 'INVOICE' ? (
				<div className="asteria-component__placeholder">
					<Group direction="horizontal">
						<div className="asteria-component__placeholder-section">
							<Icon icon="document" />
						</div>
						<div className="asteria-component__placeholder-section">
							<Title size="sm">
								{TranslationService.get([
									'client.overview.details.placeholder.delivery.title',
									`client.overview.details.${client?.type}.placeholder.delivery.title`,
								])}
							</Title>
							<Text>
								{TranslationService.get([
									'client.overview.details.placeholder.delivery.content',
									`client.overview.details.${client?.type}.placeholder.delivery.content`,
								])}
							</Text>
							<Button
								variant="link"
								label={TranslationService.get([
									'client.overview.details.placeholder.delivery.action.edit',
									`client.overview.details.${client?.type}.placeholder.delivery.action.edit`,
								])}
								onClick={handleEdit}
							/>
						</div>
					</Group>
				</div>
			) : null}
		</div>
	);
};

ClientDetailsInfo.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,
};

export default ClientDetailsInfo;
