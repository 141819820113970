import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { parseISO, subDays } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import ActionBar, {
	ActionBarSectionActions,
	ActionBarSectionContent,
	ActionBarSectionExtra,
} from '@asteria/component-actionbar';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Currencies from './Currencies';

import './styles.scss';

const InvoiceActionBarContent = React.memo(() => {
	const statistics = useSelector(InvoiceStore.selectors.statistics);
	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank;
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.();
		}

		return bank?.value?.toLowerCase?.();
	});

	return (
		<ActionBarSectionContent
			title={TranslationService.get(
				['invoices.actionbar.title', `invoices.actionbar.${bank}.title`]
					.concat(
						!statistics.count
							? [
									'invoices.actionbar.empty.title',
									`invoices.actionbar.${bank}.empty.title`,
							  ]
							: [],
					)
					.concat(
						(statistics?.details?.length ?? 0) === 1
							? [
									'invoices.actionbar.single.title',
									`invoices.actionbar.${bank}.single.title`,
							  ]
							: [],
					),
				undefined,
				statistics,
			)}
			content={TranslationService.get(
				[
					'invoices.actionbar.content',
					`invoices.actionbar.${bank}.content`,
				]
					.concat(
						!statistics.count
							? [
									'invoices.actionbar.empty.content',
									`invoices.actionbar.${bank}.empty.content`,
							  ]
							: [],
					)
					.concat(
						(statistics?.details?.length ?? 0) === 1
							? [
									'invoices.actionbar.single.content',
									`invoices.actionbar.${bank}.single.content`,
							  ]
							: [],
					),
				undefined,
				statistics,
			)}
		/>
	);
});

InvoiceActionBarContent.displayName = 'InvoiceActionBarContent';

const InvoiceActionBar = React.memo((props) => {
	const { className, onAction, onSubmit } = props;

	const hasResetSyncFeature = useFeature('invoices-reset-sync');

	const hasCurrencies = useSelector(
		(store) =>
			(InvoiceStore.selectors.statistics(store)?.details?.length ?? 0) >
			1,
	);

	const canBeReverted = useSelector((store) => {
		return InvoiceStore.selectors
			.selected(store)
			.every((invoice) =>
				(invoice?.services ?? []).some(
					({ status }) => status === 'DISCARD',
				),
			);
	});

	const hasActions = useSelector(
		(store) => !!InvoiceStore.selectors.statistics(store)?.count,
	);

	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank;
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.();
		}

		return bank?.value?.toLowerCase?.();
	});

	const state = useSelector(
		(store) => AppStore.selectors.company(store)?.state,
	);

	const connectedIntegrations = useSelector(
		(store) => {
			const integrations =
				IntegrationStore.selectors.integrations(store) ?? [];

			return integrations?.filter(
				(object) =>
					!object.key !== 'printer' && object?.config?.connected,
			);
		},
		(a, b) => isEqual(a, b),
	);

	const resetSync = useMutation({
		mutationFn: async (data) =>
			onSubmit?.('integrations:sync:update', {
				id: data?._id ?? data?.id,
				lastSync: subDays(
					data?.lastSync ? parseISO(data?.lastSync) : new Date(),
					1,
				).toISOString(),
			}),
	});

	const refetch = useMutation({
		mutationFn: async (data) =>
			onSubmit?.('integrations:import', {
				id: data?._id ?? data?.id,
			}),
	});

	const handleResetClick = React.useCallback(async () => {
		const syncedIntegrations = connectedIntegrations?.filter(
			(object) => object?.lastSync,
		);

		if (!syncedIntegrations?.length) {
			return;
		}

		await Promise.all(
			connectedIntegrations.map((integration) =>
				resetSync.mutateAsync(integration),
			),
		);

		return await Promise.all(
			connectedIntegrations.map((integration) =>
				refetch.mutateAsync(integration),
			),
		);
	}, [connectedIntegrations, refetch, resetSync]);

	const showActionBar = React.useMemo(
		() =>
			(hasResetSyncFeature && connectedIntegrations?.length) ||
			hasActions,
		[connectedIntegrations?.length, hasActions, hasResetSyncFeature],
	);

	const handleCancel = React.useCallback(
		() => onAction?.('invoices:cancel'),
		[onAction],
	);

	const revert = useMutation({
		mutationFn: async () => onAction?.('invoices:revert'),
	});

	const discard = useMutation({
		mutationFn: async () => onAction?.('invoices:discard'),
	});

	const approve = useMutation({
		mutationFn: async () => onAction?.('invoices:review'),
	});

	return (
		<ActionBar
			className={cn(
				'asteria-component__invoice-actionbar',
				{
					'asteria-state--actions': hasActions,
					'asteria-state--currencies': hasCurrencies,
				},
				className,
			)}
		>
			<InvoiceActionBarContent />
			{showActionBar ? (
				<ActionBarSectionActions>
					{hasActions ? (
						<>
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.cancel',
									'action.cancel',
									'invoices.actionbar.button.cancel',
									`invoices.actionbar.${bank}.button.cancel`,
								])}
								analyticsKey="invoices.actionbar.button.cancel"
								onClick={handleCancel}
							/>
							{canBeReverted ? (
								<Button
									variant="secondary"
									label={TranslationService.get([
										'button.revert',
										'action.revert',
										'invoices.actionbar.button.revert',
										`invoices.actionbar.${bank}.button.revert`,
									])}
									analyticsKey="invoices.actionbar.button.revert"
									onClick={revert.mutateAsync}
									loading={revert.isLoading}
									disabled={revert.isLoading}
								/>
							) : (
								<Button
									variant="secondary"
									label={TranslationService.get([
										'button.discard',
										'action.discard',
										'invoices.actionbar.button.discard',
										`invoices.actionbar.${bank}.button.discard`,
									])}
									analyticsKey="invoices.actionbar.button.discard"
									onClick={discard.mutateAsync}
									loading={discard.isLoading}
									disabled={discard.isLoading}
								/>
							)}

							<Button
								variant="primary"
								label={TranslationService.get([
									'button.approve',
									'action.approve',
									'invoices.actionbar.button.approve',
									`invoices.actionbar.${bank}.button.approve`,
								])}
								analyticsKey="invoices.actionbar.button.approve"
								onClick={approve.mutateAsync}
								tooltip={
									state !== 'APPROVED'
										? TranslationService.get([
												'invoices.actionbar.button.approve.tooltip',
												`invoices.actionbar.${bank}.button.approve.tooltip`,
										  ])
										: null
								}
								loading={approve.isLoading}
								disabled={
									approve.isLoading || state !== 'APPROVED'
								}
							/>
						</>
					) : null}
					{connectedIntegrations?.length && hasResetSyncFeature ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'invoices.actionbar.missing',
							])}
							onClick={handleResetClick}
						/>
					) : null}
				</ActionBarSectionActions>
			) : null}

			{hasCurrencies ? (
				<ActionBarSectionExtra>
					<Currencies />
				</ActionBarSectionExtra>
			) : null}
		</ActionBar>
	);
});

InvoiceActionBar.displayName = 'InvoiceActionBar';

InvoiceActionBar.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InvoiceActionBar;
