import { AuthService } from '@asteria/backend-utils-services';

import { setUser } from '@asteria/datalayer/stores/app';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export default async (options = {}) => {
	const { accessToken, dispatch } = options;

	const response = await AuthService.auth
		.me(
			{
				isBulk: true,
				fields: `
				_id
				id
				firstName
				lastName
				fullName
				settings {
					layout
					flags
					compressList
					listColumns
					hasReadAdjustable
				}
				feedback
				roles
		`,
			},
			{ token: accessToken },
		)
		.catch(() => {});

	//TODO: Clean this up in database ???
	if (response?.settings?.layout?.graph?.layout === 'flat') {
		response.settings.layout.graph.layout =
			response?.settings?.layout?.graph?.barLayout || 'grouped';
	}

	dispatch?.(setUser(response));

	return response;
};
