/* eslint-disable spellcheck/spell-checker */
import React, { useEffect, useState } from 'react';

import { Provider, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import store from '@asteria/datalayer';

import {
	formatFavicon,
	getSite,
	getTheme,
	getUrlLogin,
	getWidget,
} from '@asteria/utils-funcs/app';
import { cn } from '@asteria/utils-funcs/classes';
import loadTheme, { resetTheme } from '@asteria/utils-funcs/loadTheme';

import Features from './FeatureWrapper';
import fetchPartnerFeatures from './funcs/fetchPartnerFeatures';
import Partners from './partners';
import Footer from './partners/Footer';
import Header from './partners/Header';
import Sidebar from './partners/Sidebar';
import Router from './router';

import './app.scss';

const getSitePartner = async (site) => {
	const response = await PartnerService.partner
		.fetchByDomain({
			useTokenFromContext: false,
			domain: site,
			fields: `
			id
			name
			settings {
				themeId
				theme {
					name
				}
				hosting {
					language
					domain
				}
			}
		`,
		})
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.error(err);

			return {
				id: site ?? 'wings',
				name:
					!site || site === 'wings'
						? 'Asteria AB'
						: site[0].toUpperCase() + site.slice(1),
				settings: {
					themeId: null,
					theme: { name: site },
					hosting: { language: 'sv', domain: site },
				},
			};
		});

	return response;
};

const Updater = React.memo((props) => {
	const { partnerId } = props;

	const dispatch = useDispatch();

	React.useEffect(() => {
		fetchPartnerFeatures({
			partnerId: partnerId,
			dispatch: dispatch,
		});
	}, [dispatch, partnerId]);

	return null;
});

Updater.displayName = 'Updater';
Updater.propTypes = { partnerId: PropTypes.string };

const SiteTitles = {
	payex: {
		sv: 'Fakturaportalen',
		en: 'Invoice portal',
		da: 'Fakturaportaler',
		no: 'Fakturaportaler',
		fi: 'Laskuportaali',
	},
};

function App() {
	const [partner, setPartner] = useState(null);
	const [navigation, setNavigation] = useState(null);

	const onPartnerChange = React.useCallback((partner) => {
		if (partner) {
			const theme = getTheme(partner);

			if (theme) {
				loadTheme(theme.toLowerCase());
				localStorage.setItem(
					'asteriaSite',
					partner?.settings?.hosting?.domain || 'wings',
				);
			} else {
				resetTheme();
			}

			if (partner?.settings?.hosting?.domain) {
				localStorage.setItem(
					'asteriaSite',
					partner?.settings?.hosting?.domain || 'wings',
				);
			} else {
				localStorage.setItem('asteriaSite', 'wings');
			}

			const languageCode =
				localStorage.getItem('asteriaLanguage') ?? 'sv';
			if (partner?.settings?.theme?.name === 'lf') {
				document.title = 'Asteria Smart Cash Flow (LF Bank pilot)';
			} else if (partner?.settings?.theme?.name === 'payex') {
				document.title =
					SiteTitles?.['payex']?.[languageCode] ?? 'Fakturaportalen';
			} else {
				document.title = 'Asteria Smart Cash Flow (Demo)';
			}

			const nodes = document.querySelectorAll(
				`link[rel~='icon'],link[rel~='apple-touch-icon']`,
			);

			if (nodes.length) {
				nodes.forEach((node) => {
					if (node.href.includes('/images/favicon/')) {
						node.href = formatFavicon(
							node,
							partner?.settings?.theme?.name,
						);
					}
				});
			} else {
				const link = document.createElement('link');
				link.rel = 'icon';
				link.href = formatFavicon(null, partner?.name);

				document.head.appendChild(link);
			}
		}

		setPartner(partner);
	}, []);

	const [fullscreen, setFullscreen] = useState(
		JSON.parse(window.localStorage.getItem('AsteriaShowFullScreen')),
	);

	const onChangeFullScreen = React.useCallback((newValue) => {
		setFullscreen((value) => {
			let $value = newValue;

			if (typeof $value === 'function') {
				$value = $value(value);
			}

			window.localStorage.setItem(
				'AsteriaShowFullScreen',
				JSON.stringify($value),
			);

			return $value;
		});
	}, []);

	useEffect(() => {
		const fetch = async () => {
			const isRedirected = await getUrlLogin();

			if (isRedirected) {
				return;
			}

			const partner = await getSitePartner(getSite());
			onPartnerChange(partner);
		};

		fetch();
	}, [onPartnerChange]);

	useEffect(() => {
		if (
			(partner?.name === 'lf' ||
				partner?.settings?.hosting?.domain === 'lfbank') &&
			fullscreen === null
		) {
			onChangeFullScreen(true);
		}
	}, [
		fullscreen,
		onChangeFullScreen,
		partner?.name,
		partner?.settings?.hosting?.domain,
	]);

	const partnerState = React.useMemo(
		() => ({ state: partner, onChange: onPartnerChange }),
		[onPartnerChange, partner],
	);

	const fullScreenState = React.useMemo(
		() => ({ state: fullscreen, onChange: onChangeFullScreen }),
		[fullscreen, onChangeFullScreen],
	);

	const widget = getWidget();

	const callback = React.useCallback((action, data) => {
		if (action === 'create.history') {
			setNavigation(data);
		}
	}, []);

	if (!partner) {
		return null;
	}

	const partnerName = partner?.settings?.theme?.name?.toLowerCase?.();
	const partnerTheme = getTheme(partner)?.toLowerCase?.();

	return (
		<Provider store={store}>
			<Updater partnerId={partner?.id} />
			<Features>
				<div
					className={cn(
						'wings',
						{
							[`partner-${partnerName}`]: partnerName,
							[`theme-${partnerTheme}`]: partnerTheme,
						},
						{
							'wings-fullscreen': fullscreen,
							[`widget-${widget}`]: widget,
						},
					)}
				>
					<FeatureFlag feature="application-parter-select">
						<Partners partner={partnerState} />
					</FeatureFlag>
					<Header
						partner={partner}
						fullscreen={fullScreenState}
						theme={getTheme(partner)}
					/>
					<div className="wings__wrapper">
						<Sidebar
							partner={partner}
							fullscreen={fullScreenState}
							theme={getTheme(partner)}
							navigation={navigation}
						/>
						<div
							className={cn('wings-main', {
								'no-padding-on-credits':
									window.location.href.includes('credits'),
							})}
						>
							<Router
								widget={widget}
								partnerId={partner?.id}
								domain={partner?.settings?.hosting?.domain}
								themeId={partner?.settings?.themeId}
								callback={callback}
							/>
						</div>
					</div>
					<Footer
						partner={partner}
						fullscreen={fullScreenState}
						theme={getTheme(partner)}
					/>
				</div>
			</Features>
		</Provider>
	);
}

export default App;
